<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-card-body />
    </b-card>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SOMDashboardService } from '@/services'
import formatter from '@/mixins/formatter'
export default {
  name: 'SalesOperationManagerDashboard',

  middleware: ['auth', 'som'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },
  mixins: [formatter],

  data () {
    return {
      successfull_calls: [],
      selected: {
        from_date: this.dateFormatter(Date.now()),
        to_date: this.dateFormatter(Date.now())
      },
      tableSetting: {
        fields: [
          'index',
          {
            key: 'fullname'
          },
          {
            key: 'success',
            label: 'Number of Successfull Calls'
          }
        ]
      }
    }
  },

  mounted () {
    core.index()
    // this.getStatistics()
  },

  methods: {
    async getStatistics () {
      try {
        const { data } = await SOMDashboardService.getStatistics(`from_date=${this.selected.from_date}&to_date=${this.selected.to_date}`)
        return data
      } catch (error) {
        return []
      }
    },

    refreshTable () {
      this.$refs.table_dashboard.refresh()
    }
  }
}
</script>
